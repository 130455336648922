import {
  mdiAccount, mdiAccountMultiple,
  mdiAccountMultiplePlus,
  mdiCalendarClock,
  mdiChefHat, mdiCubeUnfolded,
  mdiFood,
  mdiFoodDrumstick,
  mdiHexagonMultiple,mdiWalletPlus,mdiAlertBox, mdiHistory, mdiMessage, mdiOfficeBuilding,mdiSettingsHelper, mdiLayers, mdiPackage, mdiStore24Hour, mdiWatch
} from '@mdi/js'

export default [
  {
    title: 'Members',
    icon: mdiAccountMultiple,
    children: [
      {
        title: 'Kitchens',
        icon: mdiChefHat,
        to: 'apps-kitchens-list',
      },
      {
        title: 'Users',
        icon: mdiAccountMultiplePlus,
        to: 'apps-user-list',
      },
      // {
      //   title: 'Companies',
      //   icon: mdiOfficeBuilding,
      //   to: 'apps-company-list',
      // },
      // {
      //   title: 'Chefs',
      //   icon: mdiChefHat,
      //   to: 'apps-chef-list',
      // },
    ],
  },
  // {
  //   title: 'Charge for users',
  //   icon: mdiWalletPlus,
  //   to: 'apps-charge-for-users-list',
  // },
  // {
  //   title: 'Sizes',
  //   icon: mdiCubeUnfolded,
  //   to: 'apps-sizes-list',
  // },
  // {
  //   title: 'Categories',
  //   icon: mdiFood,
  //   children: [
  //     {
  //       title: 'Meals',
  //       icon: mdiFoodDrumstick,
  //       to: 'apps-meals-list',
  //     },
  //     {
  //       title: 'Pre-Orders',
  //       icon: mdiCalendarClock,
  //       to: 'apps-preorders-list',
  //     },
  //   ],
  // },
  // {
  //   title: 'Items',
  //   icon: mdiHexagonMultiple,
  //   to: 'apps-items-list',
  // },
  // {
  //   title: 'Inventory',
  //   icon: mdiStore24Hour,
  //   children: [
  //     {
  //       title: 'Schedule Meals',
  //       icon: mdiWatch,
  //       to: 'apps-inventory-list',
  //     },
  //     {
  //       title: 'History',
  //       icon: mdiHistory,
  //       to: 'apps-inventory-history',
  //     },
  //   ],
  // },
  // {
  //   title: 'Packages',
  //   icon: mdiPackage,
  //   to: 'apps-packages-list',
  // },
  // {
  //   title: 'Events',
  //   icon: mdiPackage,
  //   to: 'apps-events-list',
  // },

  // {
  //   title: 'Promocodes',
  //   icon: mdiAccount,
  //   children: [
  //     {
  //       title: 'Setting Promocode',
  //       icon:mdiSettingsHelper,
  //       to: 'apps-codes-list',
  //     },
  //     {
  //       title: 'History',
  //       icon: mdiHistory,
  //       to: 'apps-codes-history',
  //     },
  //   ],
  // },
  {
    title: 'Support',
    icon: mdiMessage,
    to: 'apps-messages-list',
  },
  {
    title: 'Orders',
    icon: mdiLayers,
    to: 'apps-messages-list',
    children: [
      {
        title: 'Order Placed',
        to: 'apps-orders-placed-list',
        count:1,
      },
      {
        title: 'Preparing',
        to: 'apps-orders-prepared-list',
        count:2,
      },
      {
        title: 'Order Delivered',
        to: 'apps-orders-delivered-list',
      },
      {
        title: 'Order Canceled',
        to: 'apps-orders-canceled-list',
      },
    ],
  },
  // {
  //   title: 'Promotion',
  //   icon: mdiCubeUnfolded,
  //   to: 'apps-promotion-list',
  // },
  {
    title: 'Problems',
    icon: mdiAlertBox,
    to: 'apps-problem-list',
    count:3,
  },
]
